import React from 'react'
import logo from '../assets/imgs/logo2.png'
 
const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>We are.... <img style ={{ marginBottom: "-16vw", width:"45vw", height: "auto"}}src = {logo} /></h1>
            </header>
            <div className="content">
                {/* <ul className="actions">
                    <li><a href="#one" className="button next scrolly">Get Started</a></li>
                </ul> */}
            </div>
        </div>
    </section>
)

export default Banner
