import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic01 from '../assets/imgs/landing/pic1.jpg'
import pic02 from '../assets/imgs/landing/pic2.jpg'
import pic03 from '../assets/imgs/landing/pic3.jpg'
import pic04 from '../assets/imgs/landing/pic4.jpg'
import pic05 from '../assets/imgs/landing/pic5.jpg'
import lor1 from '../assets/images/lor11.jpg'
import unefs from '../assets/imgs/unefs.png'
import tango from '../assets/imgs/tango.jpg'

class HomeIndex extends React.Component {

    constructor() {
        super();
        this.state = {
            lang : "ro"
        }
    }
    state = {
        lang: "ro"
    }
    changeLang =  event =>{
        this.setState({
          [event.target.name] : event.target.value
        })

        console.log(this.state.lang);
      }
    render() {

        return (
            <Layout>
                <Helmet
                    title="Unidans Project"
                    meta={[
                        { name: 'description', content: 'Sample' },
                        { name: 'keywords', content: 'sample, something' },
                    ]}
                >
                </Helmet>

                <Banner />
                
                <div id="main">
                    {/* <ul style = {{zIndex: "1", marginTop: "10px", marginBottom: "10px", textAlign: "center",width: "50%", margin: "auto"}}className="features"> 
                            <button><Link to="/objectives" className="link primary">aaa</Link></button>
                            <button className = {this.state.lang === "en" ? "active" : "nonActive"} name = "lang" value = "en" onClick = {this.changeLang}> English </button>
                            <button className = {this.state.lang === "fr" ? "active" : "nonActive"} name = "lang" value = "fr" onClick = {this.changeLang}> Francais </button>
                    </ul> */}
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${pic01})`}}>
                            <header className="major">
                                <h3> Project Description </h3>
                                <p>Everything you need to know</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic02})`}}>
                            <header className="major">
                                <h3> Partners </h3>
                                <p>Professional organizations in the field</p>
                            </header>
                            <Link to="/partners" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic03})`}}>
                            <header className="major">
                                <h3> Events </h3>
                                <p>Our past and future events</p>
                            </header>
                            <Link to="/events" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic04})`}}>
                            <header className="major">
                                <h3> News </h3>
                                <p>Our latests news </p>
                            </header>
                            <Link to="/blog" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic05})`}}>
                            <header className="major">
                                <h3> Results </h3>
                                <p>We always quantify our actions</p>
                            </header>
                            <Link to="/results" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${lor1})`}}>
                            <header className="major">
                                <h3> Contact </h3>
                                <p>Stay in touch with us </p>
                            </header>
                            <Link to="/blog" className="link primary"></Link>
                        </article>
                        
                    </section>
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2> Institutional Partners </h2>
                            </header>
                            <a href = "http://unefsb.ro"><img src= {unefs}/></a>
                            <a href = "http://tangoact.ro"><img style= {{ marginLeft: "20px", width: "250px", height: "auto"}}src= {tango}/></a>

                            {/* <ul className="actions">
                                <li><Link to="/landing" className="button next">Get Started</Link></li>
                            </ul> */}
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex